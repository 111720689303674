<template>
  <div>
    <div v-if="result.valueLine.cmd == 'expect'">
      <defaultFieldTypes
        :field="field"
        :fieldAttributes="{ ...fieldAttributes }"
        v-on="$listeners"
        :templateContent="result"
        :value="value"
      ></defaultFieldTypes>
    </div>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  created() {
    this.updateColAttrs(this.result.valueLine.cmd);
  },
  props: ["result", "fieldAttributes", "field", "value"],
  watch: {
    "result.valueLine.cmd": function (newValue) {
      this.updateColAttrs(newValue);
    },
  },
  methods: {
    updateColAttrs(newValue) {
      if (newValue == "expect") {
        this.$delete(this.fieldAttributes.colAttrs, "style");
        this.$set(this.fieldAttributes.colAttrs, "class", "mr-3");
      } else {
        this.$set(this.fieldAttributes.colAttrs, "style", "max-width:0px");
        this.$set(this.fieldAttributes.colAttrs, "class", "mr-0");
      }
    },
  },
};
</script>